// import React, { useState, useEffect } from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faChevronUp } from '@fortawesome/free-solid-svg-icons';

// const ScrollToTop = () => {
//     const [isVisible, setIsVisible] = useState(false);

//     const toggleVisibility = () => {
//         if (window.pageYOffset > 300) {
//             setIsVisible(true);
//         } else {
//             setIsVisible(false);
//         }
//     };

//     const scrollToTop = () => {
//         window.scrollTo({
//             top: 0,
//             behavior: 'smooth'
//         });
//     };

//     useEffect(() => {
//         window.addEventListener('scroll', toggleVisibility);
//         return () => {
//             window.removeEventListener('scroll', toggleVisibility);
//         };
//     }, []);

//     return (
//         <div>
//             {isVisible && (
//                 <div className="scroll-to-top" onClick={scrollToTop}>
//                     <FontAwesomeIcon icon={faChevronUp} />
//                 </div>
//             )}
//         </div>
//     );
// };

// export default ScrollToTop;


import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';

const ScrollToTop = () => {
    const [isVisible, setIsVisible] = useState(false);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    useEffect(() => {
        const handleScroll = () => {
            setIsVisible(window.pageYOffset > 300);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div>
            {isVisible && (
                <div className="scroll-to-top" onClick={scrollToTop}>
                    <FontAwesomeIcon icon={faChevronUp} />
                </div>
            )}
        </div>
    );
};

export default ScrollToTop;
