import React, { useEffect, useState } from 'react'
import './Packagedesign.css'
import Footer from "./Footer";
import Aos from 'aos';
import 'aos/dist/aos.css';
// import { fetchpackagedesigncontent, fetchpackagedesignimage, base_api } from './Axios/Axios';


function Packagedesign() {

    // const [firstsection, setFirstsection] = useState([]);
    // const [secondsection, setSecondsection] = useState([]);
    // const [isBackendError, setIsBackendError] = useState(false);


    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             const firstsectiondata = await fetchpackagedesigncontent();
    //             const secondsectiondata = await fetchpackagedesignimage();
    //             setFirstsection(firstsectiondata)
    //             setSecondsection(secondsectiondata)
    //         } catch (error) {
    //             setIsBackendError(true);
    //         }
    //     };

    //     fetchData();
    // }, []);


    useEffect(() => {
        Aos.init({ duration:2000 })
    }, []);


// const renderPackagedesign = () => {
    // if (isBackendError || !firstsection.length) {

    return (
        <div>
            <div className='container-social'>
                <div className='down-content'>
                    <h3>Printing Design with mockup Image</h3>
                    {/* <p>Make your business look more professional with our graphic design services. We'll help
                        you design all types of print materials of marketing. Graphic design can be applied to a
                        wide range of media, including print with flyers, catalogs, and posters.
                    </p> */}
                </div>

                <div class='image-container mt-5'>
                    <div class='row'>
                        <div class='col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center mb-4 middleimg-pack'>
                            <img src="/Package Design/photo_1_2024-04-15_14-06-29.jpg" class='img-fluid3' />
                        </div>
                        <div class='col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center mb-4 middleimg-pack'>
                            <img src="/Package Design/photo_2_2024-04-15_14-06-29.jpg" class='img-fluid3' />
                        </div>
                        <div class='col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center mb-4 middleimg-pack'>
                            <img src="/Package Design/photo_3_2024-04-15_14-06-29.jpg" class='img-fluid3' />
                        </div>
                        <div class='col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center mb-4 middleimg-pack'>
                            <img src="/Package Design/photo_4_2024-04-15_14-06-29.jpg" class='img-fluid3' />
                        </div>

                        <div class='col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center mb-4 middleimg-pack'>
                            <img src="/Package Design/photo_5_2024-04-15_14-06-29.jpg" class='img-fluid3' />
                        </div>
                        <div class='col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center mb-4 middleimg-pack'>
                            <img src="/Package Design/photo_6_2024-04-15_14-06-29.jpg" class='img-fluid3' />
                        </div>
                        <div class='col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center mb-4 middleimg-pack'>
                            <img src="/Package Design/photo_7_2024-04-15_14-06-29.jpg" class='img-fluid3' />
                        </div>
                        <div class='col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center mb-4 middleimg-pack'>
                            <img src="/Package Design/photo_8_2024-04-15_14-06-29.jpg" class='img-fluid3' />
                        </div>

                        <div class='col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center mb-4 middleimg-pack'>
                            <img src="/Package Design/photo_9_2024-04-15_14-06-29.jpg" class='img-fluid3' />
                        </div>
                        <div class='col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center mb-4 middleimg-pack'>
                            <img src="/Package Design/photo_10_2024-04-15_14-06-29.jpg" class='img-fluid3' />
                        </div>
                        <div class='col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center mb-4 middleimg-pack'>
                            <img src="/Package Design/photo_11_2024-04-15_14-06-29.jpg" class='img-fluid3' />
                        </div>
                        <div class='col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center mb-4 middleimg-pack'>
                            <img src="/Package Design/photo_12_2024-04-15_14-06-29.jpg" class='img-fluid3' />
                        </div>

                        <div class='col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center mb-4 middleimg-pack'>
                            <img src="/Package Design/photo_13_2024-04-15_14-06-29.jpg" class='img-fluid3' />
                        </div>
                        <div class='col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center mb-4 middleimg-pack'>
                            <img src="/Package Design/photo_14_2024-04-15_14-06-29.jpg" class='img-fluid3' />
                        </div>
                        <div class='col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center mb-4 middleimg-pack'>
                            <img src="/Package Design/photo_15_2024-04-15_14-06-29.jpg" class='img-fluid3' />
                        </div>
                        <div class='col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center mb-4 middleimg-pack'>
                            <img src="/Package Design/photo_16_2024-04-15_14-06-29.jpg" class='img-fluid3' />
                        </div>

                        <div class='col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center mb-4 middleimg-pack'>
                            <img src="/Package Design/photo_17_2024-04-15_14-06-29.jpg" class='img-fluid3' />
                        </div>
                        <div class='col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center mb-4 middleimg-pack'>
                            <img src="/Package Design/photo_18_2024-04-15_14-06-29.jpg" class='img-fluid3' />
                        </div>
                        <div class='col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center mb-4 middleimg-pack'>
                            <img src="/Package Design/photo_19_2024-04-15_14-06-29.jpg" class='img-fluid3' />
                        </div>
                        <div class='col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center mb-4 middleimg-pack'>
                            <img src="/Package Design/photo_20_2024-04-15_14-06-29.jpg" class='img-fluid3' />
                        </div>

                        <div class='col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center mb-4 middleimg-pack'>
                            <img src="/Package Design/photo_21_2024-04-15_14-06-29.jpg" class='img-fluid3' />
                        </div>
                        <div class='col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center mb-4 middleimg-pack'>
                            <img src="/Package Design/photo_22_2024-04-15_14-06-29.jpg" class='img-fluid3' />
                        </div>
                        <div class='col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center mb-4 middleimg-pack'>
                            <img src="/Package Design/photo_23_2024-04-15_14-06-29.jpg" class='img-fluid3' />
                        </div>
                        <div class='col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center mb-4 middleimg-pack'>
                            <img src="/Package Design/photo_24_2024-04-15_14-06-29.jpg" class='img-fluid3' />
                        </div>

                        <div class='col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center mb-4 middleimg-pack'>
                            <img src="/Package Design/photo_25_2024-04-15_14-06-29.jpg" class='img-fluid3' />
                        </div>
                        <div class='col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center mb-4 middleimg-pack'>
                            <img src="/Package Design/photo_26_2024-04-15_14-06-29.jpg" class='img-fluid3' />
                        </div>
                        <div class='col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center mb-4 middleimg-pack'>
                            <img src="/Package Design/photo_27_2024-04-15_14-06-29.jpg" class='img-fluid3' />
                        </div>
                        {/* <div class='col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center mb-4 middleimg-pack'>
                            <img src="/Package Design/photo_28_2024-04-15_14-06-29.jpg" class='img-fluid3' />
                        </div> */}
                    </div>
                </div>
                <p className='package-content-p'>Make your business look more professional with our graphic design services. We'll help
                        you design all types of print materials for marketing. Graphic design can be applied to a
                        wide range of media, including print with flyers, catalogues, and posters.
                    </p>
            </div>
            <Footer />

        </div>
    )
} 
// else {
//     return firstsection.map((item) => (
//     <div>
//             <div className='container-social'>
//                 <div className='down-content'>
//                     <h3 dangerouslySetInnerHTML={{ __html: item.title }} />
//                     <p dangerouslySetInnerHTML={{ __html: item.subtitle }} />
//                 </div>

//                 <div class='image-container mt-5'>
//                     <div class='row'>
//             {secondsection.map((item) => 
//                         <div class='col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center mb-4 middleimg-pack'>
//                             <img src={`${base_api}${item.image}`} class='img-fluid3' />
//                         </div>
//             )}
//                     </div>
//                 </div>

//             </div>
//             <Footer />

//     </div>
// ));
// }
// };


// return (
//     <div>
//         {renderPackagedesign()}
//     </div>
// );
// }


export default Packagedesign
