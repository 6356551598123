
// import ScrollToTop from './Componentsold/ScrollToTop';
// import './Style.css';
// import "./Style1.css";




// function App() {
//   return (
//     <div>

//               hello
//               <ScrollToTop/>
//     </div>

//   );
// }

// export default App;




//// this is new App.js 


// import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// import Navbar from "./Components/Navbar";
// import Home from "./Components/Home";
// import About from "./Components/About";
// import Contact from "./Components/Contact";
// import Products from "./Components/Products";
// import About1 from "./Components/About1";
// import Contactus from "./Components/Contactus";
// import Services from "./Components/Services";
// import Testimonials from "./Components/Testimonials";
// import Portfolio from "./Components/Portfolio";


// function App() {
//   return (
//     <div>
//       {/* <Sample_website /> */}
//       {/* <Contactform/> */}

//       <Router>
//         <Navbar />
//         <Routes>
//           <Route exact path="/" element={<Home />} />
//           <Route path="/about" element={<About />} />
//           <Route path="/product" element={<Products />} />
//           <Route path="/contact" element={<Contact />} />
//           <Route path="/service" element={<Services/>} /> 
//           <Route path="/testimon" element={<Testimonials/>} />
//           <Route path="/portfolio" element={<Portfolio/>} />

//             <Route path="/about1" element={<About1/>} />
//             <Route path="/contactus" element={<Contactus />} />

//         </Routes>
//       </Router>

//  {/* <Contactform/> */}

//     </div>
//   );
// }

// export default App;

import './Style1.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from './Componentsold/Navbar';
import Home from "./Componentsold/Home";
import About from "./Componentsold/About";
import Services from "./Componentsold/Services";
import Portfolio from "./Componentsold/Portfolio";
import Ourservice from './Componentsold/Ourservice';
import Testimonials from "./Componentsold/Testimonials";
import Contact from "./Componentsold/Contact";
import Footer from "./Componentsold/Footer";
import About1 from "./Componentsold/About1";
import Portfolio1 from './Componentsold/Portfolio1';
import Testimonials1 from './Componentsold/Testimonials1';
import Services1 from './Componentsold/Services1';
import Contact1 from './Componentsold/Contact1';
import Watsapplink from './Componentsold/Watsapplink';
import Digital from './Componentsold/Digital';
import Youtube from './Componentsold/Youtube';
import Facebookpage from './Componentsold/Facebookpage';
import Instagram1 from './Componentsold/Instagram1';
import Google from './Componentsold/Google';
import Socialmediamarketing from './Componentsold/Socialmediamarketing';
import Paidpromotionads from './Componentsold/Paidpromotionads';
import Trial from './Componentsold/Trial';
import Searchengine from './Componentsold/Searchengine';
import Webdevelopement from './Componentsold/Webdevelopement';


function App() {
  return (
    <div>
      <Watsapplink />
      <Router>

        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/about1" element={<About1 />} />
          <Route path='/testimon1' element={<Testimonials1 />} />
          <Route path='/webdevelopement' element={<Services1 />} />
          <Route path="/portfolio" element={<Portfolio1 />} />
          <Route path="/contact" element={<Contact1 />} />
          <Route path="/digitalmarketing" element={<Digital />} />
          <Route path="/youtubebusiness" element={<Youtube />} />
          <Route path="/facebookbusiness" element={< Facebookpage />} />
          <Route path="/instagrambusiness" element={< Instagram1 />} />
          <Route path="/googlebusiness" element={< Google />} />
          <Route path="/socialmediamarketing" element={< Socialmediamarketing />} />
          <Route path="/paidpromotionads" element={< Paidpromotionads/>} />
          <Route path="/trial" element={<Trial /> } />
          <Route path="/searchengineoptimization" element={<Searchengine /> } />
          {/* <Route path="/webdevelope" element={<Webdevelopement /> } /> */}
        </Routes>
      </Router>

    </div>
  );
}

export default App;
