// import React from 'react'
// import Navbar from "./Navbar";
// import ScrollToTop from "./ScrollToTop";
// import './Digital.css'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import {FaFacebook, FaInstagram, FaYoutube, FaGoogle } from 'react-icons/fa'
// import Footer from "./Footer";


// const Digital = () => {
//     return (
//         <div id="digital">
//             <Navbar />

//             <div className="main-content">
//                 <div className="header-bg">

//                     <div className="homecontent">
//                         <div className="">
//                             <img src='/Images/client2.jpg'height="100" width="100" alt="clinet image" />
//                         </div>


//                         <div className="text-center">
//                             <p>Digital Marketing</p>
//                             <h2>Get in touch with the Best<br />Digital Marketing Company <br /> <span> in Madurai </span></h2>
//                         </div>

//                     </div>
//                 </div>
//                 <ScrollToTop />
//             </div>

//             <div>
//                 <div class='row justify-content-between'>
//                     <div class='col-lg-8 col-md-12 col-sm-12 col-xs-12 heading p-0'>
//                         <h3>Social Media Marketing</h3>
//                         <p>An Action hero needs personal ingenuity, a variety of <br />
//                             talents and imagination to impress His Audience in order <br />
//                             to stand alone in the crowd.. Social Media Marketing has <br />
//                             a unique personality in generating Huge Audience and <br />
//                             Leads for Marketers on Facebook, Instagram, YouTube, <br />
//                             Twitter, Pinterest Google Business, Linkedin..,</p>
//                     </div>
//                     <div class='col-lg-4 col-md-12 col-sm-12 col-xs-12 right-image'>
//                         <img src="/Imagefile/Home_image_removeshadow1.png" height="100" width="100" class="img-fluid homesection-image" alt="Responsive Image" />
//                     </div>
//                 </div>
//             </div>

//             <div class="full-content text-center">
//                 <div class="row box-content">
//                     <div class="col">
//                         <div className='letter d-flex justify-content-evenly'>
//                             <div className='icon'>
//                             <FaFacebook className="icons youtube" />
//                             </div>
//                             <div>
//                             <p>Facebook Page</p>
//                             </div>

//                         </div>
//                     </div>
//                     <div class="col">
//                     <div className='letter d-flex justify-content-evenly'>
//                             <div className='icon'>
//                             <FaInstagram className="icons youtube" />
//                             </div>
//                             <div>
//                             <p>Instagram Page</p>
//                             </div>

//                         </div>
//                     </div>
//                     <div class="col ">
//                     <div className='letter d-flex justify-content-evenly'>
//                             <div className='icon'>
//                             <FaYoutube className="icons youtube" />
//                             </div>
//                             <div>
//                             <p>Youtube Page</p>
//                             </div>

//                         </div>
//                     </div>
//                     <div class="col ">
//                     <div className='letter d-flex justify-content-evenly'>
//                             <div className='icon'>
//                             <FaGoogle className="icons youtube" />
//                             </div>
//                             <div>
//                             <p>Google Business Page</p>
//                             </div>
//                     </div>
//                 </div>
//                 </div>
//             </div>

//             <Footer />

//         </div>
//     )
// }

// export default Digital









// import React, { useEffect, useState } from 'react';
// import Navbar from "./Navbar";
// import ScrollToTop from "./ScrollToTop";
// import Footer from "./Footer";
// import './Digital.css';
// import { Link } from 'react-router-dom';
// import Aos from 'aos';
// import 'aos/dist/aos.css';
// import { fetchdmfirstsection, fetchdmsecondsection, base_api } from './Axios/Axios';


// function Digital() {
//     const [firstsection, setFirstsection] = useState([]);
//     const [secondsection, setSecondsection] = useState([]);
//     const [isBackendError, setIsBackendError] = useState(false); // Initialize correctly

//     useEffect(() => {
//         const fetchData = async () => {
//             try {
//                 const firstsectiondata = await fetchdmfirstsection();
//                 const secondsectiondata = await fetchdmsecondsection();
//                 setFirstsection(firstsectiondata);
//                 setSecondsection(secondsectiondata);
//             } catch (error) {
//                 console.error('Error fetching data:', error);
//                 setIsBackendError(true);
//             }
//         };

//         fetchData();
//     }, []);

//     useEffect(() => {
//         Aos.init({ duration: 2000 });
//     }, []);

//     const renderdigitalsection = () => {
//         const defaultData = {
//             title: 'Digital Marketing',
//             subtitle: 'Get in touch with the best <br /> Best digital marketing company in Madurai',
//             title1: 'Socail Media Marketing',
//             subtitle1: 'An Action hero needs personal ingenuity, a variety of talents and imagination to impress His Audience in order to stand alone in the crowd.. Social Media Marketing has a unique personality in generating Huge Audience and Leads for Marketers on Facebook, Instagram, YouTube, Twitter, Pinterest Google Business..',
//             image: './Digital_marketing_images/dm_1sec_1.png'
//           };
//         if (isBackendError || !firstsection.length === 0 || !secondsection.length === 0) {
//             return (
//                 <div id="digital" className='main-contents'>
//                     <Navbar />
//                     <div className="main-content-digital">
//                         <div className="digital-header-bg">
//                             <div className="row text-contents">
//                                 <div className="col-lg-6 col-md-6 col-sm-12 first-content">
//                                     <img src="/Imagefile/First Banner Character@2x.png" className="responsive-image" alt="Responsive Image" />
//                                 </div>
//                                 <div className="col-lg-6 col-md-6 col-sm-12 text-content">
//                                     <p>Digital Marketing</p>
//                                     <h2>Get in touch with the Best<br />Digital Marketing Company <br /> in Madurai </h2>
//                                 </div>
//                             </div>
//                         </div>
//                         <ScrollToTop />
//                     </div>
//                     <div className='second-content'>
//                         <div className='row justify-content-between'>
//                             <div className='col-lg-8 col-md-12 col-sm-12 col-xs-12 heading p-0'>
//                                 <h3>Social Media Marketing</h3>
//                                 <h6>An Action hero needs personal ingenuity, a variety of
//                                     talents and imagination to impress His Audience in order
//                                     to stand alone in the crowd.. Social Media Marketing has
//                                     a unique personality in generating Huge Audience and 
//                                     Leads for Marketers on Facebook, Instagram, YouTube,
//                                     Twitter, Pinterest Google Business..</h6>
//                                 <div className="row box-content">
//                                     <div className="col-lg-3 col-md-6 col-sm-12">
//                                         <Link to="/Facebookpage" id="facebook">
//                                             <div className='letter'>
//                                                 <p>Facebook Page</p>
//                                             </div>
//                                         </Link>
//                                     </div>
//                                     <div className="col-lg-3 col-md-6 col-sm-12">
//                                         <Link to="/Instagram1" id="Instagram">
//                                             <div className='letter'>
//                                                 <p>Instagram Page</p>
//                                             </div>
//                                         </Link>
//                                     </div>
//                                     <div className="col-lg-3 col-md-6 col-sm-12" id="youtube">
//                                         <Link to="/Youtube">
//                                             <div className='letter'>
//                                                 <p>Youtube Page</p>
//                                             </div>
//                                         </Link>
//                                     </div>
//                                     <div className="col-lg-3 col-md-6 col-sm-12">
//                                         <Link to="/Google">
//                                             <div className='letter'>
//                                                 <p>Google Page</p>
//                                             </div>
//                                         </Link>
//                                     </div>
//                                 </div>
//                             </div>
//                             <div className='col-lg-4 col-md-12 col-sm-12 col-xs-12 right-image'>
//                                 <img src="/Imagefile/5a229b838aaea9.1057140315122174755681@2x.png" className='second-con-img' alt="Responsive Image" />
//                             </div>
//                         </div>
//                     </div>
//                     <Footer />
//                 </div>
//             );
//         } else {
//             return (
//                 <div id="digital" className='main-contents'>
//                     <Navbar />
//                     <div className="main-content-digital">
//                         <div className="digital-header-bg">
//                             {firstsection.map((item, index) => (
//                                 <div key={index} className="row text-contents">
//                                     <div className="col-lg-6 col-md-6 col-sm-12 first-content">
//                                         <img src={`${base_api}${item.image}` || defaultData.image} className="responsive-image" alt="Responsive Image" />
//                                     </div>
//                                     <div className="col-lg-6 col-md-6 col-sm-12 text-content">
//                                         <p dangerouslySetInnerHTML={{ __html: item.title || defaultData.title}} />
//                                         <h2 dangerouslySetInnerHTML={{ __html: item.subtitle || defaultData.subtitle }} />
//                                     </div>
//                                 </div>
//                             ))}
//                         </div>
//                         <ScrollToTop />
//                     </div>
//                     <div className='second-content'>
//                         {secondsection.map((item, index) => (
//                             <div key={index} className='row justify-content-between'>
//                                 <div className='col-lg-8 col-md-12 col-sm-12 col-xs-12 heading p-0'>
//                                     <h3 dangerouslySetInnerHTML={{ __html: item.title || defaultData.title1}} />
//                                     <h6 dangerouslySetInnerHTML={{ __html: item.subtitle || defaultData.subtitle1}} />
//                                     <div className="row box-content">
//                                         <div className="col-lg-3 col-md-6 col-sm-12">
//                                             <Link to="/Facebookpage" id="facebook">
//                                                 <div className='letter'>
//                                                     <p>Facebook Page</p>
//                                                 </div>
//                                             </Link>
//                                         </div>
//                                         <div className="col-lg-3 col-md-6 col-sm-12">
//                                             <Link to="/Instagram1" id="Instagram">
//                                                 <div className='letter'>
//                                                     <p>Instagram Page</p>
//                                                 </div>
//                                             </Link>
//                                         </div>
//                                         <div className="col-lg-3 col-md-6 col-sm-12" id="youtube">
//                                             <Link to="/Youtube">
//                                                 <div className='letter'>
//                                                     <p>Youtube Page</p>
//                                                 </div>
//                                             </Link>
//                                         </div>
//                                         <div className="col-lg-3 col-md-6 col-sm-12">
//                                             <Link to="/Google">
//                                                 <div className='letter'>
//                                                     <p>Google Page</p>
//                                                 </div>
//                                             </Link>
//                                         </div>
//                                     </div>
//                                 </div>
//                                 <div className='col-lg-4 col-md-12 col-sm-12 col-xs-12 right-image'>
//                                     <img src={`${base_api}${item.image}`} className='second-con-img' alt="Responsive Image" />
//                                 </div>
//                             </div>
//                         ))}
//                     </div>
//                     <Footer />
//                 </div>
//             );
//         }
//     };

//     return (
//         <div>
//             {renderdigitalsection()}
//         </div>
//     );
// }

// export default Digital;





import React, { useEffect, useState } from 'react';
import Navbar from "./Navbar";
import ScrollToTop from "./ScrollToTop";
import Footer from "./Footer";
import './Digital.css';
import { Link } from 'react-router-dom';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { fetchdmfirstsection, fetchdmsecondsection, base_api } from './Axios/Axios';


function Digital() {
    const [firstsection, setFirstsection] = useState([]); // Initialize with empty array
    const [secondsection, setSecondsection] = useState([]); // Initialize with empty array
    const [isBackendError, setIsBackendError] = useState(false); // Initialize correctly

    const handleLinkClick = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const firstsectiondata = await fetchdmfirstsection();
                const secondsectiondata = await fetchdmsecondsection();
                setFirstsection(firstsectiondata || []); // Ensure data is an array
                setSecondsection(secondsectiondata || []); // Ensure data is an array
            } catch (error) {
                // console.error('Error fetching data:', error);
                setIsBackendError(true);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        Aos.init({ duration: 2000 });
    }, []);

    const renderdigitalsection = () => {
        const defaultData = {
            title: 'Digital Marketing',
            subtitle: 'Get in touch with the best <br /> Best digital marketing company in Madurai',
            title1: 'Social Media Marketing',
            subtitle1: 'An Action hero needs personal ingenuity, a variety of talents and imagination to impress His Audience in order to stand alone in the crowd.. Social Media Marketing has a unique personality in generating Huge Audience and Leads for Marketers on Facebook, Instagram, YouTube, Twitter, Pinterest Google Business..',
            image: './Digital_marketing_images/dm_1sec_1.png'
        };

        if (isBackendError || firstsection.length === 0 || secondsection.length === 0) {
            return (
                <div id="digital" className='main-contents'>
                    <Navbar />
                    <div className="main-content-digital">
                        <div className="digital-header-bg">
                            <div className="row text-contents">
                                <div className="col-lg-6 col-md-6 col-sm-12 first-content">
                                    <img src="/Imagefile/First Banner Character@2x.png" className="responsive-image" alt="Responsive Image" />
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12 text-content">
                                    <p>Digital Marketing</p>
                                    <h2>Get in touch with the Best<br />Digital Marketing Company <br /> in Madurai </h2>
                                </div>
                            </div>
                        </div>
                        <ScrollToTop />
                    </div>
                    <div className='second-content'>
                        <div className='row justify-content-between'>
                            <div className='col-lg-8 col-md-12 col-sm-12 col-xs-12 heading p-0'>
                                <h3>Social Media Marketing</h3>
                                <h6>An Action hero needs personal ingenuity, a variety of
                                    talents and imagination to impress His Audience in order
                                    to stand alone in the crowd. Social Media Marketing has
                                    a unique personality in generating Huge Audience and 
                                    Leads for Marketers on Facebook, Instagram, YouTube,
                                    Twitter, Pinterest Google Business..</h6>
                                <div className="row box-content">
                                    <div className="col-lg-3 col-md-6 col-sm-12">
                                        <Link to="/facebookbusiness" id="facebook" onClick={handleLinkClick}>
                                            <div className='letter'>
                                                <p>Facebook Page</p>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12">
                                        <Link to="/instagrambusiness" id="Instagram" onClick={handleLinkClick}>
                                            <div className='letter'>
                                                <p>Instagram Page</p>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12" id="youtube">
                                        <Link to="/youtubebusiness" onClick={handleLinkClick}>
                                            <div className='letter'>
                                                <p>Youtube Page</p>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12">
                                        <Link to="/googlebusiness" onClick={handleLinkClick}>
                                            <div className='letter'>
                                                <p>Google Page</p>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-12 col-sm-12 col-xs-12 right-image'>
                                <img src="/Imagefile/5a229b838aaea9.1057140315122174755681@2x.png" className='second-con-img' alt="Responsive Image" />
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            );
        } else {
            return (
                <div id="digital" className='main-contents'>
                    <Navbar />
                    <div className="main-content-digital">
                        <div className="digital-header-bg">
                            {firstsection.map((item, index) => (
                                <div key={index} className="row text-contents">
                                    <div className="col-lg-6 col-md-6 col-sm-12 first-content">
                                        <img src={`${base_api}${item.image}` || defaultData.image} className="responsive-image" alt="Responsive Image" />
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12 text-content">
                                        <p dangerouslySetInnerHTML={{ __html: item.title || defaultData.title }} />
                                        <h2 dangerouslySetInnerHTML={{ __html: item.subtitle || defaultData.subtitle }} />
                                    </div>
                                </div>
                            ))}
                        </div>
                        <ScrollToTop />
                    </div>
                    <div className='second-content'>
                        {secondsection.map((item, index) => (
                            <div key={index} className='row justify-content-between'>
                                <div className='col-lg-8 col-md-12 col-sm-12 col-xs-12 heading p-0'>
                                    <h3 dangerouslySetInnerHTML={{ __html: item.title || defaultData.title1 }} />
                                    <h6 dangerouslySetInnerHTML={{ __html: item.subtitle || defaultData.subtitle1 }} />
                                    <div className="row box-content">
                                        <div className="col-lg-3 col-md-6 col-sm-12">
                                            <Link to="/facebookbusiness" id="facebook">
                                                <div className='letter'>
                                                    <p>Facebook Page</p>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-sm-12">
                                            <Link to="/instagrambusiness" id="Instagram">
                                                <div className='letter'>
                                                    <p>Instagram Page</p>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-sm-12" id="youtube">
                                            <Link to="/youtubebusiness">
                                                <div className='letter'>
                                                    <p>youTube Page</p>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-sm-12">
                                            <Link to="/googlebusiness">
                                                <div className='letter'>
                                                    <p>Google Page</p>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-md-12 col-sm-12 col-xs-12 right-image'>
                                    <img src={`${base_api}${item.image}`} className='second-con-img' alt="Responsive Image" />
                                </div>
                            </div>
                        ))}
                    </div>
                    <Footer />
                </div>
            );
        }
    };

    return (
        <div>
            {renderdigitalsection()}
        </div>
    );
}

export default Digital;

