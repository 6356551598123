

import React, { useState, useRef, useEffect } from 'react';
import './Navbar.css';
import { Link } from 'react-router-dom';
import { FaWhatsapp, FaTimes, FaBars } from 'react-icons/fa';

function Header() {
  const [mobileNavIsOpen, setMobileNavIsOpen] = useState(false);
  const sidebarRef = useRef(null);

  // Toggle the Mobile Nav open / close
  const handleMobileNavClick = () => {
    setMobileNavIsOpen(!mobileNavIsOpen);
  };

  // Handle click outside of the sidebar
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setMobileNavIsOpen(false);
      }
    };

    if (mobileNavIsOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [mobileNavIsOpen]);

  return (
    <div>
      <nav className="navbar">
        <a href="/">
        <img src="/Imagefile/Phoenix Logo - 01 2.png" height="55" alt="header_image" className="logo_image"/>
        </a>

        {/*Windows Navbar */}
        <div className="menu-container">
          <ul className="menu_list">
            <li className='me-5 mt-3 normal-nav'>
              <Link to="/" aria-current="page">
                Home
              </Link>
            </li>
            <li className='me-5 mt-3 normal-nav'>
              <Link to="/webdevelopement" aria-current="page">
                Service
              </Link>
              <ul className='submenu'>
                <li className="digital-link">
                  <Link to="/digitalmarketing" aria-current="page" className='digital-marketing'>
                    Digital Marketing
                  </Link>
                  <ul className="service-toggle">
                    <li className='social-toggle'>
                      <Link to="/socialmediamarketing">Social Media Marketing</Link>
                    </li>
                    <li className='search-toggle'>
                      <Link to="/searchengineoptimization">Search Engine Optimization</Link>
                    </li>
                    <li className='paid-toggle'>
                      <Link to="/paidpromotionads">Paid Promotion Ads</Link>
                    </li>
                  </ul>
                </li>
                <li className='web-link'><Link to="/webdevelopement">Web Development</Link></li>
              </ul>
            </li>
            <li className='me-5 mt-3 normal-nav'>
              <Link to="/socialmediamarketing" aria-current="page">
                Portfolio
              </Link>
            </li>
            <li className='me-5 mt-3 normal-nav'>
              <Link to="/contact" aria-current="page">
                Contact
              </Link>
            </li>
            <li className='chatwithus normal-navbar'>
              <FaWhatsapp className="youtube" />
              <Link  rel="noopener noreferrer"
              target="_blank" to={`https://api.whatsapp.com/send?phone=${9025428057}`} aria-current="page">
                Chat With us
              </Link>
            </li>
          </ul>
        </div>
      </nav>

      <nav className="mobileNav_container">
        {/* <img src="/Imagefile/Phoenix Logo - 01 2.png" width="50" alt="logo" className="mobileNav_logo_left" /> */}
        <a href="/" rel="noopener noreferrer">
          <img src="Imagefile/logo.png" width="50" alt="logo" className="mobileNav_logo_left" />
        </a>
        {mobileNavIsOpen ? (
          <button className="close-btn" onClick={handleMobileNavClick}>
            {/* <FaTimes /> */}
          </button>
        ) : (
          <button className="mobile-menu-icon" onClick={handleMobileNavClick}>
            <FaBars />
          </button>
        )}
      </nav>

      {/* Sidebar for mobile */}
      {mobileNavIsOpen && (
        <div className="sidebar-mobile" ref={sidebarRef}>
          <div className='d-flex' style={{ justifyContent: 'space-between' }}>
            <div>
              <a href="/">
              <img src="/Imagefile/Phoenix Logo - 01 2.png" height="60" alt="header_image" className="mobile-navbar-image" />
              </a>
              </div>
            {mobileNavIsOpen ? (
              <a className="close-btn-mbl" onClick={handleMobileNavClick}>
                <FaTimes />
              </a>
            ) : (
              <button className="mobile-menu-icon" onClick={handleMobileNavClick}>
                <FaBars />
              </button>
            )}
          </div>

          <ul className='list-row'>
            <li className='sidebar-options'>
              <Link to="/" onClick={handleMobileNavClick}>Home</Link>
            </li>
            <li className='service-options sidebar-options'>
              <Link to="/webdevelopement" aria-current="page">
                Service
              </Link>
              <ul className='submenu'>
                <li className="digital-link">
                  <Link to="/digitalmarketing" aria-current="page"  className='digital-marketing'>
                    Digital Marketing
                  </Link>
                  <ul className="service-toggle">
                    <li className='social-toggle'>
                      <Link to="/socialmediamarketing">Social Media Marketing</Link>
                    </li>
                    <li className='search-toggle'>  
                      <Link to="/searchengineoptimization">Search Engine Optimization</Link>
                    </li>
                    <li className='paid-toggle'>
                      <Link to="/paidpromotionads">Paid Promotion Ads</Link>
                    </li>
                  </ul>
                </li>
                <li><Link to="/webdevelopement" className='web-link'>Web Development</Link></li>
              </ul>
            </li>

{/* <li className='service-options sidebar-options'>
      <div onClick={toggleServiceMenu} aria-current="page">
        Service
      </div>
      {isServiceOpen && (
        <ul className='submenu'>
          <li className="digital-link">
            <div onClick={toggleDigitalMenu} className='digital-marketing'>
              Digital Marketing
            </div>
            {isDigitalOpen && (
              <ul className="service-toggle">
                <li className='social-toggle'>
                  <Link to="/socialmediamarketing">Social Media Marketing</Link>
                </li>
                <li className='search-toggle'>  
                  <Link to="/search">Search Engine Optimization</Link>
                </li>
                <li className='paid-toggle'>
                  <Link to="/Paidpromotionads">Paid Promotion Ads</Link>
                </li>
              </ul>
            )}
          </li>
          <li><Link to="/service1" className='web-link'>Web Development</Link></li>
        </ul>
      )}
    </li> */}
          
            <li className='sidebar-options'><Link to="/socialmediamarketing" onClick={handleMobileNavClick}>Portfolio</Link></li>
            <li className='sidebar-options'><Link to="/contact" onClick={handleMobileNavClick}>Contact</Link></li>
            <li className='chatwithus-mobile'>
              <FaWhatsapp className=""/>
              <Link to={`https://api.whatsapp.com/send?phone=${9025428057}`} className='chat' aria-current="page">
                Chat With us
              </Link>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
}

export default Header;
