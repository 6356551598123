import React, { useEffect, useRef, useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import './Testimonial.css';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { fetchsixthsection, fetchsixthsectiontest, base_api } from "./Axios/Axios";


function Testimonials() {
  const carouselRef = useRef(null);

  const [firstsection, setFirstsection] = useState([]);
  const [secondsection, setSecondsection] = useState([]);
  const [isBackendError, setIsBackendError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const firstsectiondata = await fetchsixthsection();
        const Secondsectiondata = await fetchsixthsectiontest();
        setFirstsection(firstsectiondata);
        setSecondsection(Secondsectiondata);
      } catch (error) {
        setIsBackendError(true);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    Aos.init({ duration: 2000 })
  }, []);

  const renderSixthsection = () => {
    const defaultData = {
      title: 'What Client Says',
      subtitle: 'Here is a sneak peek of What Our Client Says',
      review: 'This is my honest review about " Phoenix digital marketing company". I am wonder how they be like this much unique. I am totally satisfied with their wonderful services.',
      reviewname: 'Amman Restaurant',
      image: './Imagefile/Phoenix Web Image.png'
    };
    if (isBackendError || !firstsection.length || !secondsection.length) {
  return (
    <div className="testimon-header">
      <div className="testimon-content">
        <h3>What client says</h3>
        <p>Here is a sneak peek of what Our Client Says</p>
        <div className="carousel-container">
          <Carousel ref={carouselRef}>
            <Carousel.Item>
              <div className="testimon-review">
                <img
                  src="Imagefile/6thsection1.png"
                  className="testimon-1stpng"
                />
                <div className="review1">
                  <div className="quatation">
                    <img
                      src="Imagefile/6thsection5.png"
                      className="quatationpng"
                    />
                    <img src="Imagefile/star3.png" className="starpng" />
                  </div>
                  <h5>Amman Restaurant</h5>
                  <p>
                    This is my honest review about" Phoenix digital marketing
                    company". I am wonder how they be like this much unique. I
                    am totally satisfied with their wonderful services. Like
                    poster designs , video making, banners, sticker designs,
                    everything was good.. 
                  </p>
                </div>
              </div>
            </Carousel.Item>
            {/* Add more Carousel.Item components for additional testimonials */}
            <Carousel.Item>
              <div className="testimon-review">
                <img
                  src="Imagefile/6thsection1.png"
                  className="testimon-1stpng"
                />
                <div className="review1">
                  <div className="quatation">
                    <img
                      src="Imagefile/6thsection5.png"
                      className="quatationpng"
                    />
                    <img src="Imagefile/star3.png" className="starpng" />
                  </div>
                  <h5>RMR Silks</h5>
                  <p>
                    {" "}
                    I have searched many of digital marketing agencies around
                    madurai but finally i found one of the best team in madurai.
                    The overall experience was great..! I would say that they
                    are professionals in their work. My shopping Website was
                    completed on time and as per my expectations. I would highly
                    recommend this company for my friends.
                  </p>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="testimon-review">
                <img
                  src="Imagefile/6thsection1.png"
                  className="testimon-1stpng"
                />
                <div className="review1">
                  <div className="quatation">
                    <img
                      src="Imagefile/6thsection5.png"
                      className="quatationpng"
                    />
                    <img src="Imagefile/star3.png" className="starpng" />
                  </div>
                  <h5>Gourmet Bakery</h5>
                  <p>
                    {" "}
                    Productive and Professional Digital Marketing Agency. They
                    are very professional to work and I wish the whole team for
                    the great work. Their suggestions and input shows how
                    committed they are to understanding our business and
                    delivering the right results for us. This is the most
                    important thing for us. I strongly recommend this digital
                    marketing agency to my friends and families.
                  </p>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="testimon-review">
                <img
                  src="Imagefile/6thsection1.png"
                  className="testimon-1stpng"
                />
                <div className="review1">
                  <div className="quatation">
                    <img
                      src="Imagefile/6thsection5.png"
                      className="quatationpng"
                    />
                    <img src="Imagefile/star3.png" className="starpng" />
                  </div>
                  <h5>Vikram multispeciality hospital</h5>
                  <p>
                    {" "}
                    We reached out to Phoenix marketing for getting our
                    multispeciality hospitals SEO services. Definitely say that
                    They exceeded our expectations. They performed very well and
                    excellence in making a professional and attractive Services
                    . Thanks for Your Support And Services..
                  </p>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="testimon-review">
                <img
                  src="Imagefile/6thsection1.png"
                  className="testimon-1stpng"
                />
                <div className="review1">
                  <div className="quatation">
                    <img
                      src="Imagefile/6thsection5.png"
                      className="quatationpng"
                    />
                    <img src="Imagefile/star3.png" className="starpng" />
                  </div>
                  <h5>Century Minds</h5>
                  <p>
                    {" "}
                    If I want to say something about the review means definitely
                    i can say this " Wonderful Creative Team" Your service met
                    my expectations. You guys have a great team and creative
                    professionals. Which is to be appreciated. I have a good
                    response via your Logo designs. I am also very happy to
                    having this team for my designs. Keep up the good work
                    team..
                  </p>
                </div>
              </div>
            </Carousel.Item>
          </Carousel>
        </div>
      </div>
    </div>
  );
} else {
return firstsection.map((item) => (
  <div className="testimon-header">
  <div className="testimon-content">
    <h3 dangerouslySetInnerHTML={{ __html: item.heading || defaultData.title }} />
    <p dangerouslySetInnerHTML={{ __html: item.content }} />
    <div className="carousel-container">
      <Carousel ref={carouselRef}>
        <Carousel.Item>
        {secondsection.map((item) => (
          <div className="testimon-review">
            <img
              src={`${base_api}${item.image1}`}
              className="testimon-1stpng"
            />
            <div className="review1">
              <div className="quatation">
                <img
                  src={`${base_api}${item.image2}`}
                  className="quatationpng"
                />
                <img src={`${base_api}${item.image3}`} className="starpng" />
              </div>
              <h5 dangerouslySetInnerHTML={{ __html:item.restaurant || defaultData.reviewname }} />
              <p dangerouslySetInnerHTML={{ __html: item.review || defaultData.review }} />
            </div>
          </div>
        ))}
        </Carousel.Item>
      </Carousel>
    </div>
  </div>
</div>
))
  }
};

return (
  <div>
    {renderSixthsection()}
  </div>
);
}

export default Testimonials;

