import React from "react";
import Footer from "./Footer";
import ScrollToTop from "./ScrollToTop";
import Navbar from "./Navbar";



function Portfolio1() {
  return (

<div id="portfolio1">

<div>
    <Navbar/>
</div>

  <div className="main-content">
      <div className="port-heading">
        <h1>Protfolio page </h1>
      </div>
      <div className="port-content" style={{ marginBottom: "50px" }}>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Soluta,
          quisquam expedita. Eaque impedit ipsa voluptatem, quo vero
          consequuntur atque quam repudiandae, autem, ullam eius. Cupiditate<br/>
          fuga ullam sequi cumque magnam optio dolores voluptates eos quia quasi
          incidunt unde, libero accusantium est aut tempore, atque nesciunt<br/>
          perspiciatis, suscipit magni dolorum quas. Lorem ipsum dolor sit amet,<br/><br/>
          consectetur adipisicing elit. Soluta, quisquam expedita. Eaque impedit
          ipsa voluptatem, quo vero consequuntur atque quam repudiandae, autem,<br/>
          ullam eius. Cupiditate fuga ullam sequi cumque magnam optio dolores<br/>
          voluptates eos quia quasi incidunt unde, libero accusantium est aut
          tempore, atque nesciunt perspiciatis, suscipit magni dolorum quas.<br/>

          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Soluta,
          quisquam expedita. Eaque impedit ipsa voluptatem, quo vero
          consequuntur atque quam repudiandae, autem, ullam eius. Cupiditate<br/>
          fuga ullam sequi cumque magnam optio dolores voluptates eos quia quasi
          incidunt unde, libero accusantium est aut tempore, atque nesciunt<br/>
          perspiciatis, suscipit magni dolorum quas. Lorem ipsum dolor sit amet,<br/><br/>
          consectetur adipisicing elit. Soluta, quisquam expedita. Eaque impedit
          ipsa voluptatem, quo vero consequuntur atque quam repudiandae, autem,<br/>
          ullam eius. Cupiditate fuga ullam sequi cumque magnam optio dolores<br/>
          voluptates eos quia quasi incidunt unde, libero accusantium est aut
          tempore, atque nesciunt perspiciatis, suscipit magni dolorum quas.<br/>

          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Soluta,
          quisquam expedita. Eaque impedit ipsa voluptatem, quo vero
          consequuntur atque quam repudiandae, autem, ullam eius. Cupiditate<br/>
          fuga ullam sequi cumque magnam optio dolores voluptates eos quia quasi
          incidunt unde, libero accusantium est aut tempore, atque nesciunt<br/>
          perspiciatis, suscipit magni dolorum quas. Lorem ipsum dolor sit amet,<br/><br/>
          consectetur adipisicing elit. Soluta, quisquam expedita. Eaque impedit
          ipsa voluptatem, quo vero consequuntur atque quam repudiandae, autem,<br/>
          ullam eius. Cupiditate fuga ullam sequi cumque magnam optio dolores<br/>
          voluptates eos quia quasi incidunt unde, libero accusantium est aut
          tempore, atque nesciunt perspiciatis, suscipit magni dolorum quas.
        </p>
      </div>
      <ScrollToTop/>
      <Footer />
  </div>
</div>

  );
}

export default Portfolio1;
