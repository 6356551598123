import React, { useEffect, useState } from 'react'
import Footer from "./Footer";
import Aos from "aos";
import 'aos/dist/aos.css';
import './Designuiux.css'


function Designuiux() {

    useEffect(() => {
        Aos.init({ duration: 2000 });
    }, []);
    
  return (
    <div>
        <div className='container-social'>
                    <div className='down-content'>
                        <h3>UI & UX Design</h3>
                    </div>

                    <div className='image-container-design mt-5'>
                        <div className='row'>
                            <div className='col-lg-6 col-md-12 col-sm-12 middleimg'>
                                <img src="/Imagefile/Frame 41 (1).png" className='fixed-size-image'/>
                            </div>
                            <div className='col-lg-6 col-md-12 col-sm-12 middleimg'>
                                <img src="/Imagefile/screencapture-madurastones-2024-07-08-12_09_33.png" className='fixed-size-image'/>
                            </div>
                        </div>

                        <p className='design-content-p'>
                            A person who knows the need and search of others is forever better. He seems to be a good
                            hero to those who are stuck in search. UI's role in digital marketing is used to leverage
                            branding to create positive perception around your brand. Interface design can help you 
                            steer website visitors to do what you want-whether that's getting them to fill out a form to
                            download information. Call you for a consultation or buy a product.
                        </p>
                    </div>



        </div>
        <Footer />

    </div>
  );
}; 


export default Designuiux

