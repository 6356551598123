import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import ScrollToTop from "./ScrollToTop";
import './Services1.css';
import Navbar from "./Navbar";
import { FaShoppingCart, FaLaptopCode, FaPaintBrush, FaTruck } from 'react-icons/fa';
import Webdesign from "./Webdesign";
import Ecommerce from "./Ecommerce";
import Delivery from "./Delivery";
import Webdevelopement from "./Webdevelopement";
import Aos from "aos";
import 'aos/dist/aos.css';
import { fetchservicepage, base_api } from "./Axios/Axios";


function Services1() {
  const [showContent, setServicePageContent] = useState(true);
  const [showWebContent, setWebdevelopementContent] = useState(false);
  const [showDesignContent, setWebDesignContent] = useState(false);
  const [showCommerceContent, setECommerceContent] = useState(false);

  const [firstsection, setFirstsection] = useState([]);
  const [isBackendError, setIsBackendError] = useState(false);

  const handledesignclick = () => {
    setServicePageContent(true);
    setWebdevelopementContent(false);
    setWebDesignContent(false);
    setECommerceContent(false);
  }

  const handledesignclick2 = () => {
    setServicePageContent(false);
    setWebdevelopementContent(true);
    setWebDesignContent(false);
    setECommerceContent(false);
  }

  const handledesignclick3 = () => {
    setServicePageContent(false);
    setWebdevelopementContent(false);
    setWebDesignContent(true);
    setECommerceContent(false);
  }

  const handledesignclick4 = () => {
    setServicePageContent(false);
    setWebdevelopementContent(false);
    setWebDesignContent(false);
    setECommerceContent(true);
  }

  const [services, setServices] = useState([]);


  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div id="service1">
      <Navbar />
      <div className="service-main-content mt-3">
      <div className='social-header-bg-service'>
          <div className='row text-contents-service'>
            <div className='col-lg-6 col-md-6 col-sm-12 offset-lg-0 offset-md-0 offset-sm-0 text-content-service'>
              <div className="left-content">
                <h1>Web development service</h1>
                <div className='line-service1'></div>
                <h4>We deliver not just a website</h4>
                <h4>but a sale - driven portal!!!.....!</h4>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 text-image-service">
              <img src="/Imagefile/Man 1.png" height="400" width="400" />
            </div>
          </div>
        </div>

        <div className="pagein-content-service m-5">
          <h3>Our Service</h3>
        </div>

        <div className="image-container">
          <div className="row">
            <div className="col-lg-3 col-md-4 col-sm-6 mb-3">
              <div className="card-service" onClick={handledesignclick}>
                <div className='icon'>
                  <FaLaptopCode
                  className="icons youtube black-icon m-2" />
                </div>
                <p>Web Development</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 mb-3">
              <div className="card-service" onClick={handledesignclick2}>
                <div className='icon'>
                  <FaPaintBrush className="icons youtube black-icon m-2" />
                </div>
                <p>Web Design & Redesign</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 mb-3">
              <div className="card-service" onClick={handledesignclick3}>
                <div className='icon'>
                  <FaShoppingCart 
                  className="icons youtube black-icon m-2" />
                </div>
                <p className="p-2">E-commerce Website</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 mb-3">
              <div className="card-service" onClick={handledesignclick4}>
                <div className='icon'>
                  <FaTruck className="icons youtube black-icon m-2" />
                </div>
                <p>Delivery App Development</p>
              </div>
            </div>
          </div>
        </div>

        {showContent && (

        <Webdevelopement />
        )}

        {showWebContent && (

          <Webdesign />
        )}

        {showDesignContent && (

          <Ecommerce />
        )}

        {showCommerceContent && (

          <Delivery />
        )}

        <ScrollToTop />
        <Footer />
      </div>
    </div>
  );
}


export default Services1;
