import React from "react";
import Footer from "./Footer";
import {FaYoutube, FaTwitter, FaCartPlus} from 'react-icons/fa'
// import './DisplayIcons.css'

function Testimonials1() {
  return (
    <div id="testimon1">
      <div className="testimon-heading">
        {/* <h1>Testimonials page </h1> */}
        <FaYoutube className="icons youtube" />
        <FaTwitter className="icons twitter"/>
        <FaCartPlus className="icons cart"/>
        
      </div>
      <div className="testimon-content" style={{ marginBottom: "50px" }}>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Soluta,
          quisquam expedita. Eaque impedit ipsa voluptatem, quo vero
          consequuntur atque quam repudiandae, autem, ullam eius. Cupiditate
          fuga ullam sequi cumque magnam optio dolores voluptates eos quia quasi
          incidunt unde, libero accusantium est aut tempore, atque nesciunt
          perspiciatis, suscipit magni dolorum quas. Lorem ipsum dolor sit amet,
          consectetur adipisicing elit. Soluta, quisquam expedita. Eaque impedit
          ipsa voluptatem, quo vero consequuntur atque quam repudiandae, autem,
          ullam eius. Cupiditate fuga ullam sequi cumque magnam optio dolores
          voluptates eos quia quasi incidunt unde, libero accusantium est aut
          tempore, atque nesciunt perspiciatis, suscipit magni dolorum quas.
        </p>
        <p>
          consequuntur atque quam repudiandae, autem, ullam eius. Cupiditate
          <br />
          fuga ullam sequi cumque magnam optio dolores voluptates eos quia quasi
          incidunt unde, libero accusantium est aut tempore, atque nesciunt
          <br />
          perspiciatis, suscipit magni dolorum quas. Lorem ipsum dolor sit amet,
          <br />
          <br />
          consectetur adipisicing elit. Soluta, quisquam expedita. Eaque impedit
          ipsa voluptatem, quo vero consequuntur atque quam repudiandae, autem,
          <br />
          ullam eius. Cupiditate fuga ullam sequi cumque magnam optio dolores
          <br />
          voluptates eos quia quasi incidunt unde, libero accusantium est aut
          tempore, atque nesciunt perspiciatis, suscipit magni dolorum quas.
          <br />
          consequuntur atque quam repudiandae, autem, ullam eius. Cupiditate
          <br />
          fuga ullam sequi cumque magnam optio dolores voluptates eos quia quasi
          incidunt unde, libero accusantium est aut tempore, atque nesciunt
          <br />
          perspiciatis, suscipit magni dolorum quas. Lorem ipsum dolor sit amet,
          <br />
          <br />
          consectetur adipisicing elit. Soluta, quisquam expedita. Eaque impedit
          ipsa voluptatem, quo vero consequuntur atque quam repudiandae, autem,
          <br />
          ullam eius. Cupiditate fuga ullam sequi cumque magnam optio dolores
          <br />
          voluptates eos quia quasi incidunt unde, libero accusantium est aut
          tempore, atque nesciunt perspiciatis, suscipit magni dolorum quas.
          <br />
          consequuntur atque quam repudiandae, autem, ullam eius. Cupiditate
          <br />
          fuga ullam sequi cumque magnam optio dolores voluptates eos quia quasi
          incidunt unde, libero accusantium est aut tempore, atque nesciunt
          <br />
          perspiciatis, suscipit magni dolorum quas. Lorem ipsum dolor sit amet,
          <br />
          <br />
          consectetur adipisicing elit. Soluta, quisquam expedita. Eaque impedit
          ipsa voluptatem, quo vero consequuntur atque quam repudiandae, autem,
          <br />
          ullam eius. Cupiditate fuga ullam sequi cumque magnam optio dolores
          <br />
          voluptates eos quia quasi incidunt unde, libero accusantium est aut
          tempore, atque nesciunt perspiciatis, suscipit magni dolorum quas.
          <br />
        </p>
      </div>

      <Footer />
    </div>
  );
}

export default Testimonials1;
