import React from 'react'
import Navbar from "./Navbar";
import Footer from "./Footer";
import './Facebook.css'
import ScrollToTop from "./ScrollToTop";


const Facebookpage = () => {
  return (
    <div>
        <Navbar />
        
        <div className='fbmain'>
            <p>Facebook Business / Fan Page Maintenance</p>

<div className='second-main'>
<div class="row row-cols-1 row-cols-md-3 g-4 second-main2">
  <div class="col-lg-4 col-md-6 col-sm-12">
    <div class="card">
      <img src="/Facebook - Client/Facebook (1).png" class="card-img-top" height="300" width="200" alt="..."/>
      <div class="card-body">
        <h5 class="fb-card-title">Amman Restaurant</h5>
        <p class="card-text"></p>
      </div>
    </div>
  </div>
  <div class="col-lg-4 col-md-6 col-sm-12">
    <div class="card">
      <img src="/Facebook - Client/Facebook (2).png" class="card-img-top" height="300" width="200" alt="..."/>
      <div class="card-body">
        <h5 class="fb-card-title">Balagopalan Jewellery Mart</h5>
        <p class="card-text"></p>
      </div>
    </div>
  </div>
  <div class="col-lg-4 col-md-6 col-sm-12">
    <div class="card">
      <img src="/Facebook - Client/Facebook (3).png" class="card-img-top" height="300" width="200" alt="..."/>
      <div class="card-body">
        <h5 class="fb-card-title">We Craft Floors</h5>
        <p class="card-text"></p>
      </div>
    </div>
  </div>
  <div class="col-lg-4 col-md-6 col-sm-12">
    <div class="card">
      <img src="/Facebook - Client/Facebook (4).png" class="card-img-top" height="300" width="200" alt="..."/>
      <div class="card-body">
        <h5 class="fb-card-title">Senthil Jewellery Mart</h5>
        <p class="card-text"></p>
      </div>
    </div>
  </div>
  <div class="col-lg-4 col-md-6 col-sm-12">
    <div class="card">
      <img src="/Facebook - Client/Facebook (5).png" class="card-img-top" height="300" width="200" alt="..."/>
      <div class="card-body">
        <h5 class="fb-card-title">MK Oil Mill</h5>
        <p class="card-text"></p>
      </div>
    </div>
  </div>
  <div class="col-lg-4 col-md-6 col-sm-12">
    <div class="card">
      <img src="/Facebook - Client/Facebook (6).png" class="card-img-top" height="300" width="200" alt="..."/>
      <div class="card-body">
        <h5 class="fb-card-title">Hi Bro Mens Madurai</h5>
        <p class="card-text"></p>
      </div>
    </div>
  </div>
  <div class="col-lg-4 col-md-6 col-sm-12">
    <div class="card">
      <img src="/Facebook - Client/Facebook (7).png" class="card-img-top" height="300" width="200" alt="..."/>
      <div class="card-body">
        <h5 class="fb-card-title">Varshan Enterprises</h5>
        <p class="card-text"></p>
      </div>
    </div>
  </div>
  <div class="col-lg-4 col-md-6 col-sm-12">
    <div class="card">
      <img src="/Facebook - Client/Facebook.png" class="card-img-top" height="300" width="200" alt="..."/>
      <div class="card-body">
        <h5 class="fb-card-title">Saaral bakes cakes sweets</h5>
        <p class="card-text"></p>
      </div>
    </div>
  </div>
</div>
</div>

</div>
<ScrollToTop />
<Footer />
    </div>
  )
}

export default Facebookpage
