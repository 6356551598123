import React from 'react'
import Footer from './Footer'
import ScrollToTop from './ScrollToTop'
import Navbar from './Navbar'


function About1() {
  return (
    <div id='about1'>

<div>
    <Navbar/>
</div>

<div className='main-content'>  

        <section className="wwa content mt">
          <div className="wwa-heading mb">
                <h2>About section</h2>
          </div>
          <div className="wwa-content flex-design"> 
                <div className="vision wwa-box">
                  <h3>vision</h3>
                  <p>Our support team will get back to in 24-h during standard business hours.</p>
                </div>
                <div className="mission wwa-box">
                  <h3>Mission</h3>
                  <p>Our support team will get back to in 24-h during standard business hours.</p>
                </div>
                <div className="whoweyou wwa-box">
                  <h3>who we are</h3>
                  <p>Our support team will get back to in 24-h during standard business hours.</p>
                </div>
          </div>
        </section>


        <section >
        <div class="container-fluid">
          <div class="about1-row">
            <div class="col-lg-6 col-md-6 col-12 my-2" style={{width: '40%'}}>
              <img src="Imagefile/5thsecBG.jpg" class="img-fluid" style={{width: '100%', height:"400px"}} />
            </div>

            <div class="col-lg-6 col-md-6 col-12 p-lg-5 p-2">
              <h1>About Us page</h1>
              <p>
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Labore
                laudantium velit eaque iste consequatur atque, sint voluptatum
                praesentium nesciunt expedita culpa ea adipisci ducimus ab nam
                mollitia officia dolorem liberoLorem, ipsum dolor sit amet
                consectetur adipisicing elit. Labore laudantium velit eaque iste
                consequatur atque, sint voluptatum praesentium nesciunt expedita
                culpa ea adipisci ducimus ab nam mollitia officia dolorem
                libero,Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                Labore laudantium velit eaque iste consequatur atque, sint
                voluptatum praesentium nesciunt expedita culpa ea adipisci
                ducimus ab nam mollitia officia dolorem liberoLorem, ipsum dolor
                sit amet consectetur adipisicing elit. Laboreducimus ab nam
                mollitia officia dolorem libero
              </p>
            </div>
          </div>
        </div>
      </section>
      <ScrollToTop/>
        <Footer/>
  </div>
</div>
  )
}

export default About1