import React, { useEffect, useState } from 'react'
import './Delivery.css'
import Aos from 'aos';
import 'aos/dist/aos.css';


function Delivery() {

  useEffect(() => {
    Aos.init({ duration: 2000 })
  }, []);

  return (
    <div>
    <div className="serviceaboutsection">
      <div className="aboutcontent">
        <div  className="delivery-text-center">
          <h1>Delivery App Development</h1>
          <div className="delivery-underline"></div>
          <p>
            We Support businesses by leaving our expertise to launch the
            delivery application that makes the user's interaction at ease.
          </p>
        </div>

        <div className="aboutsection-image-all-service-delivery">
          <img src="/Imagefile/Delivery App Development.png" className="aboutsection-image" />
        </div>
      </div>
    </div>
  </div>
  );
};


export default Delivery

