import React, { useState, useEffect } from 'react'
import './Webdesign.css';
import { fetchwebdesignservice, base_api } from './Axios/Axios';
import Aos from "aos";
import 'aos/dist/aos.css';


function Webdesign() {

  const [services, setServices] = useState([]);


  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);


  return (
    <div>
      <div className="serviceaboutsection">
        <div className="aboutcontent">
          <div className="web-text-center">
            <h1>Web Design & Redesign</h1>
            <div className="webdesign-underline"></div>
            <p>
              We know that Impactful & Engaging Web Design is the cornerstone to
              establishing a solid digital presence for your business. We thrive
              at building an alluring visual representation of your business
              through our innovative Ideas & Technologies.
            </p>
            <p>
              In order to beat out the overwhelming competition, we also offer
              website redesigning to improve the features of the website.
            </p>
          </div>

          <div  className="aboutsection-image-all-service">
            <img
              src="/Imagefile/Web Design & Redesign.png"
              className="aboutsection-image"
            />
          </div>
        </div>
      </div>
    </div>
  );
}


export default Webdesign


