
import React, { useState, useEffect } from "react";
import About from "./About";
import Services from "./Services";
import Portfolio from "./Portfolio";
import Testimonials from "./Testimonials";
import Footer from "./Footer";
import Ourservice from "./Ourservice";
import Quote from "./Quote";
import Client from "./Client";
import ScrollToTop from "./ScrollToTop";
import Navbar from "./Navbar";
import Counter from "./Counter";
import { fetchfirstsection, base_api } from "./Axios/Axios";
import Aos from "aos";
import 'aos/dist/aos.css';
import { FaArrowRight } from "react-icons/fa";
import './Home.css';


function Home() {
  const [firstsection, setFirstsection] = useState([]);
  const [isBackendError, setIsBackendError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const firstsectionData = await fetchfirstsection();
        setFirstsection(firstsectionData);
      } catch (error) {
        // console.error("Error fetching first section data:", error);
        setIsBackendError(true);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  const renderFirstSection = () => {
    const defaultData = {
      title: 'Digital Marketing Firm a marketing consulting firm',
      subtitle: 'Build, Grow and <br /> Manage your BRAND <br />  with Phoenix Marketing',
      buttontext: 'Get Started',
      image: './Imagefile/Home_image_removeshadow1.png'
    };

    if (isBackendError || !firstsection.length) {
      return (
        <div className="homecontent">
          <div data-aos='fade-right' className="text-center">
            <p>A Integrated marketing firm</p>
            <h2 dangerouslySetInnerHTML={{ __html: defaultData.subtitle }} />
            <a className="startbtn">
              {defaultData.buttontext}
              < FaArrowRight  className="ms-2"/>
            </a>
          </div>
          <div data-aos='fade-left' className="text-center mt-4 p-0">
            <img
              src={defaultData.image}
              className="img-fluid-home"
              alt="Home Image"
              href="/"
            />
          </div>
        </div>
      );
    } else {
      return firstsection.map((item) => (
      <React.Fragment key={item.id}>
        <div className="homecontent">
          <div data-aos='fade-right' className="text-center">
            <p dangerouslySetInnerHTML={{ __html: item.title || defaultData.title }} />
            <h2 dangerouslySetInnerHTML={{ __html: item.subtitle || defaultData.subtitle }} />
            <a className="startbtn" href={item.button_link}>
              {item.button_text || defaultData.buttontext}
              <i className="fa-solid fa-arrow-right ml-2"></i>
            </a>
          </div>
          <div data-aos='fade-left' className="text-center mt-4">
            <img
              src={`${base_api}${item.image}`}
              className="img-fluid-home"
              alt="Home Image"
            />
          </div>
        </div>
        </React.Fragment>
      ));
    }
  };

  return (
    <div>
      <Navbar />
      <div className="main-content-home">
        <div className="header-bg">
          <div className="">
            <div className="row">
              <div className="col-12">
                {renderFirstSection()}
              </div>
            </div>
          </div>
        </div>
      </div>
      <About />
      <Services />
      <Client />
      <Counter />
      <Portfolio />
      <Ourservice />
      <Testimonials />
      <Quote />
      <Footer />
      <ScrollToTop />
    </div>
  );
}

export default Home;
