// import React, { useState, useEffect } from "react";
// import './About.css';
// import { fetchsecondsection, base_api } from "./Axios/Axios";
// import Aos from 'aos';
// import 'aos/dist/aos.css';


// function About() {
//   const [secondsection, setSecondsection] = useState([]);
//   const [isBackendError, setIsBackendError] = useState(false);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const secondsectionData = await fetchsecondsection();
//         console.log("Fetched data:", secondsectionData); // Log fetched data
//         setSecondsection(secondsectionData);
//       } catch (error) {
//         console.error("Error fetching about section data:", error);
//         setIsBackendError(true);
//       }
//     };

//     fetchData();
//   }, []);

//   useEffect(() => {
//     Aos.init({ duration: 2000 });
//   }, []);

//   const renderAboutContent = () => {
//     // Check if there's an error or if the data array is empty
//     if (isBackendError || !secondsection.length) {
//       // Render static frontend data
//       console.log("Rendering static frontend data due to error or no backend data");
//       return (
//         <div className="row head-container">
//           <div className="col-lg-6 col-md-12 col-sm-12">
//             <div className="about-img-container">
//               <img
//                 src="./Imagefile/Phoenix Web Image.png"
//                 className="about-image img-fluid"
//                 alt="About Image"
//               />
//             </div>
//           </div>
//           <div className="col-lg-6 col-md-12 col-sm-12">
//             <div className="about-content2">
//               <h1>About us f'data</h1>
//               <h3>We are top leading and Premier <br /> creative digital marketing company in Madurai f'data</h3>
//               <p>Our passion for innovation and dedication to excellence drives every project
//                 we undertake. From humble beginnings, we've grown into a team of creative minds, 
//                 technical wizards, and strategic thinkers.
//               </p>
//               <button type="button" className="about-button">
//                 Explore more <i className="fas fa-plus"></i>
//               </button>
//             </div>
//           </div>
//         </div>
//       );
//     } else {
//       // Render data from the backend
//       console.log("Rendering backend data:", secondsection);
//       return (
//         <div className="row head-container">
//           {secondsection.map((data) => (
//             <React.Fragment key={data.id}>
//               <div className="col-lg-6 col-md-12 col-sm-12">
//                 <div className="about-img-container">
//                   <img
//                     src={`${base_api}${data.image}`}
//                     className="about-image img-fluid"
//                     alt="About Image"
//                   />
//                 </div>
//               </div>
//               <div className="col-lg-6 col-md-12 col-sm-12">
//                 <div className="about-content2">
//                   <h1 dangerouslySetInnerHTML={{ __html: data.title }} />
//                   <h3 dangerouslySetInnerHTML={{ __html: data.subtitle }} />
//                   <p dangerouslySetInnerHTML={{ __html: data.subtitleone }} />
//                   <button type="button" className="about-button">
//                     Explore more <i className="fas fa-plus"></i>
//                   </button>
//                 </div>
//               </div>
//             </React.Fragment>
//           ))}
//         </div>
//       );
//     }
//   };

//   return (
//     <div className="about-background">
//       {renderAboutContent()}
//     </div>
//   );
// }

// export default About;


import React, { useState, useEffect } from "react";
import './About.css';
import { fetchsecondsection, base_api } from "./Axios/Axios";
import Aos from 'aos';
import 'aos/dist/aos.css';


const fetchReactData = async () => {
  
};

function About() {
  const [secondsection, setSecondsection] = useState(null);
  const [isBackendError, setIsBackendError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const secondsectionData = await fetchsecondsection();
        if (!secondsectionData || secondsectionData.length === 0) {
          throw new Error("No data from backend");
        }
        // console.log("Fetched data from backend:", secondsectionData); // Log fetched data
        setSecondsection(secondsectionData);
      } catch (error) {
        // console.error("Error fetching backend data, falling back to React data:", error);
        setIsBackendError(true);

        // Fetch React data as a fallback
        try {
          const reactData = await fetchReactData();
          // console.log("Fetched data from React fallback:", reactData); // Log fetched React data
          setSecondsection(reactData);
        } catch (fallbackError) {
          // console.error("Error fetching React data:", fallbackError);
        }
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  const renderAboutContent = () => {
    // Define default values for the content
    const defaultData = {
      title: 'About Us',
      subtitle: 'We are a top leading and premier <br /> creative marketing consultancy',
      subtitleone: 'Our passion for innovation and dedication to excellence drives every project we undertake. From humble beginnings, we\'ve grown into a team of creative minds, technical wizards, and strategic thinkers.',
      image: './Imagefile/Phoenix Web Image.png'
    };

    // Check if there's an error or if the data array is empty
    if (isBackendError || !secondsection || secondsection.length === 0) {
      // Render static frontend data
      // console.log("Rendering static frontend data due to error or no backend data");
      return (
        <div className="about-center">
        <div className="row head-container">
          <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="about-img-container">
              <img
                src={defaultData.image}
                className="about-image img-fluid"
                alt="About Image"
              />
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="about-content2">
              <h1>{defaultData.title}</h1>
              <h3 dangerouslySetInnerHTML={{ __html: defaultData.subtitle }} />
              <p>{defaultData.subtitleone}</p>
              {/* <button type="button" className="about-button">
                Explore more <i className="fas fa-plus"></i>
              </button> */}
            </div>
          </div>
        </div>
        </div>
      );
    } else {
      // Render data from the backend or React fallback
      // console.log("Rendering data:", secondsection);
      return (
        <div className="row head-container">
          {secondsection.map((data) => (
            <React.Fragment key={data.id}>
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="about-img-container">
                  <img
                    src={data.image.startsWith('http') ? data.image : `${base_api}${data.image}`}
                    className="about-image img-fluid"
                    alt="About Image"
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="about-content2">
                  <h1 dangerouslySetInnerHTML={{ __html: data.title || defaultData.title }} />
                  <h3 dangerouslySetInnerHTML={{ __html: data.subtitle || defaultData.subtitle }} />
                  <p dangerouslySetInnerHTML={{ __html: data.subtitleone || defaultData.subtitleone }} />
                  <button type="button" className="about-button">
                    Explore more <i className="fas fa-plus"></i>
                  </button>
                </div>
              </div>
            </React.Fragment>
          ))}
        </div>
      );
    }
  };

  return (
    <div className="about-background">
      {renderAboutContent()}
    </div>
  );
}

export default About;
