// import React, { useEffect, useState } from "react";
// import './Services.css';
// import { fetchthirdsection, base_api } from "./Axios/Axios";
// import Aos from "aos";
// import 'aos/dist/aos.css';


// function Services() {

//   const [thirdsection, setThirdsection] = useState([]);
//   const [isBackendError, setIsBackendError] = useState([]);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const thirdsectiondata = await fetchthirdsection();
//         setThirdsection(thirdsectiondata);
//       } catch (error) {
//         setIsBackendError(true);
//       }
//     };

//     fetchData();
//   }, [])

//   useEffect(() => {
//     Aos.init({ duration: 2000 })
//   })

//   const renderServicecontent = () => {

// if (isBackendError || !thirdsection.length) {
//   return (
// <div class="servicebackground">
// <div class="servicehead">
//   <div class="left-section col-lg-4 col-md-12 col-sm-12 d-flex">
//     <div>
//       <img src="./Imagefile/3rd_design.png" />
//     </div>
//     <div>
//       <p>Design</p>
//     </div>
//   </div>
//   <div class="center-section col-lg-4 col-md-12 col-sm-12 d-flex">
//     <div>
//       <img src="./Imagefile/3rd_technology.png" />
//     </div>
//     <div>
//       <p>Technology</p>
//     </div>
//   </div>
//   <div class="right-section col-lg-4 col-md-12 col-sm-12 d-flex">
//     <div>
//       <img src="./Imagefile/3rd_business.png" />
//     </div>
//     <div>
//       <p>Business</p>
//     </div>
//   </div>
// </div>
// </div>
//   );} else {
//     return(
// <div class="servicebackground">
//   {thirdsection.map((data) => (
//     <React.Fragment key={data.id}>
// <div class="servicehead">
//   <div class="left-section col-lg-4 col-md-12 col-sm-12 d-flex">
//     <div>
//       <img src={`${base_api}${data.image}`} />
//     </div>
//     <div> 
//       <p dangerouslySetInnerHTML={{ __html: data.text1 }}></p>
//     </div>
//   </div>
//   <div class="center-section col-lg-4 col-md-12 col-sm-12 d-flex">
//     <div>
//       <img src={`${base_api}${data.image}`} />
//     </div>
//     <div>
//       <p dangerouslySetInnerHTML={{ __html: data.text2 }}></p>
//     </div>
//   </div>
//   <div class="right-section col-lg-4 col-md-12 col-sm-12 d-flex">
//     <div>
//       <img src={`${base_api}${data.image}`} />
//     </div>
//     <div>
//       <p dangerouslySetInnerHTML={{ __html: data.text3 }}></p>
//     </div>
//   </div>
// </div>
// </React.Fragment>
// ))}
// </div>
//     );
//   }
// };

// return (
//   <div className="">
//     {renderServicecontent()}
//   </div>
// );
// }

// export default Services;


import React, { useEffect, useState } from "react";
import './Services.css';
import { fetchthirdsection, base_api } from "./Axios/Axios";
import Aos from "aos";
import 'aos/dist/aos.css';

function Services() {
  const [thirdsection, setThirdsection] = useState([]);
  const [isBackendError, setIsBackendError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const thirdsectiondata = await fetchthirdsection();
        // console.log("Fetched data:", thirdsectiondata); // Log fetched data
        setThirdsection(thirdsectiondata);
        setIsBackendError(false);
      } catch (error) {
        // console.error("Error fetching services data:", error);
        setIsBackendError(true);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  const renderServicecontent = () => {
    if (isBackendError || !thirdsection.length) {
      return (
        <div className="servicebackground">
          <div className="servicehead">
            <div className="left-section col-lg-4 col-md-12 col-sm-12 d-flex">
              <div>
                <img src="./Imagefile/3rd_design.png" alt="Design" />
              </div>
              <div>
                <p>Design</p>
              </div>
            </div>
            <div className="center-section col-lg-4 col-md-12 col-sm-12 d-flex">
              <div>
                <img src="./Imagefile/3rd_technology.png" alt="Technology" />
              </div>
              <div>
                <p>Technology</p>
              </div>
            </div>
            <div className="right-section col-lg-4 col-md-12 col-sm-12 d-flex">
              <div>
                <img src="./Imagefile/3rd_business.png" alt="Business" />
              </div>
              <div>
                <p>Business</p>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="servicebackground">
          {thirdsection.map((data) => (
            <div className="servicehead" key={data.id}>
              <div className="left-section col-lg-4 col-md-12 col-sm-12 d-flex">
                <div>
                  <img src={`${base_api}${data.image1}`} alt="Service" />
                </div>
                <div>
                  <p dangerouslySetInnerHTML={{ __html: data.text1 }}></p>
                </div>
              </div>
              <div className="center-section col-lg-4 col-md-12 col-sm-12 d-flex">
                <div>
                  <img src={`${base_api}${data.image2}`} alt="Service" />
                </div>
                <div>
                  <p dangerouslySetInnerHTML={{ __html: data.text2 }}></p>
                </div>
              </div>
              <div className="right-section col-lg-4 col-md-12 col-sm-12 d-flex">
                <div>
                  <img src={`${base_api}${data.image3}`} alt="Service" />
                </div>
                <div>
                  <p dangerouslySetInnerHTML={{ __html: data.text3 }}></p>
                </div>
              </div>
            </div>
          ))}
        </div>
      );
    }
  };

  return (
    <div className="services-container">
      {renderServicecontent()}
    </div>
  );
}

export default Services;
