import React, { useEffect, useState } from 'react';
import Navbar from "./Navbar";
import { Link } from 'react-router-dom';
import './Socailmediamarketing.css';
import Socialmediaposter from './Socialmediaposter';
import Packagedesign from './Packagedesign';
import Logowork from './Logowork';
import Videomaking from './Videomaking';
import Designuiux from './Designuiux';
import Aos from "aos";
import 'aos/dist/aos.css';
import Footer from "./Footer";

function Socialmediamarketing() {
    // Initialize state
    const [showContent, setShowContent] = useState(false);
    const [showContent1, setShowContent1] = useState(false);
    const [showContainerSocial, setShowContainerSocial] = useState(false);
    const [showVideo, setShowVideo] = useState(true); // Default to show Video Making
    const [showDesign, setShowDesign] = useState(false);

    useEffect(() => {
        Aos.init({ duration: 2000 });
    }, []);

    // Handler for Package Design
    const handleLinkClick = () => {
        setShowContent(true);
        setShowContent1(false);
        setShowContainerSocial(false);
        setShowVideo(false);
        setShowDesign(false);
    };

    // Handler for Logo Works
    const handleLinkClick1 = () => {
        setShowContent(false);
        setShowContent1(true);
        setShowContainerSocial(false);
        setShowVideo(false);
        setShowDesign(false);
    };

    // Handler for Social Media Posters
    const handleSocialPostersClick = () => {
        setShowContent(false);
        setShowContent1(false);
        setShowContainerSocial(true);
        setShowVideo(false);
        setShowDesign(false);
    };

    // Handler for Video Making
    const handleVideoClick = () => {
        setShowVideo(true);
        setShowContent(false);
        setShowContent1(false);
        setShowContainerSocial(false);
        setShowDesign(false);
    };

    // Handler for UI/UX Design
    const handleDesignClick = () => {
        setShowDesign(true);
        setShowContent(false);
        setShowContent1(false);
        setShowVideo(false);
        setShowContainerSocial(false);
    };

    const defaultData = {
        title: 'Design Portfolio',
        subtitle: 'We are a top leading and premier <br /> creative digital marketing company in Madurai',
    };

    return (
        <div>
            <Navbar />
            <div className='social-header-bg'>
                <div className='row text-contents'>
                    <div className='col-lg-6 col-md-12 col-sm-12 offset-lg-6 offset-md-6 text-content'>
                        <h3>Design Portfolio</h3>
                        <div className='line'></div>
                        <p>Explore Our Portfolio Projects. Get about
                            glimpse into our world, Design is our
                            passion. Projects are an Our Art. We
                            Create meaningful and diverse projects
                            for all levels of ambition.
                        </p>
                    </div>
                </div>
            </div>

            <div className="row box-content">
                <div className='col-lg-1'></div>
                <div className="col-lg-2 col-md-6 col-sm-12">
                    <Link onClick={handleVideoClick}>
                        <div className='headings'>
                            <p>Video Making</p>
                        </div>
                    </Link>
                </div>
                <div className="col-lg-2 col-md-6 col-sm-12">
                    <Link id="facebook" onClick={handleSocialPostersClick}>
                        <div className='headings'>
                            <p>Social Media Posters</p>
                        </div>
                    </Link>
                </div>
                <div className="col-lg-2 col-md-6 col-sm-12">
                    <Link id="Instagram" onClick={handleLinkClick}>
                        <div className='headings'>
                            <p>Package Design</p>
                        </div>
                    </Link>
                </div>
                <div className="col-lg-2 col-md-6 col-sm-12" id="youtube">
                    <Link onClick={handleLinkClick1}>
                        <div className='headings'>
                            <p>Logo Works</p>
                        </div>
                    </Link>
                </div>
                <div className="col-lg-2 col-md-6 col-sm-12">
                    <Link onClick={handleDesignClick}>
                        <div className='headings'>
                            <p>UI/UX Design</p>
                        </div>
                    </Link>
                </div>
                <div className='col-lg-1'></div>
            </div>

            {showContainerSocial && (
                <Socialmediaposter />
            )}

            {showContent && (
                <Packagedesign />
            )}

            {showContent1 && (
                <Logowork />
            )}

            {showVideo && (
                <Videomaking />
            )}

            {showDesign && (
                <Designuiux />
            )}

            {/* <Footer /> */}
        </div>
    );
}

export default Socialmediamarketing;
