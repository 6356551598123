import React, { useEffect, useState } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { fetchwebdevelopmentservice, base_api } from './Axios/Axios';


function Webdevelopement() {

    useEffect(() => {
        Aos.init({ duration: 2000 })
    }, []);

    
  return (
<div className="serviceaboutsection">
    <div className="aboutcontent">
      <div  className="aboutsection-image-all-service">
        <img src="/Imagefile/Web Development.png" className="aboutsection-image" />
      </div>
      <div  className="service-text-center">
        <h1>Web Development</h1>
        <div className="service-underline"></div>
        <p>Web application development is being employed widely in today's businesses across the globe. We create B2B and B2C compatible
          web applications to meet the business challenges. Our developers will create web application that the way you need them, from single 
          page application to customized complex web applications. Own a web application and transform your business. We offer 360 degree
          support in developing your web application.
        </p>
      </div>
    </div>
</div>
  );
} 


export default Webdevelopement


