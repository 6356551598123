import React, { useEffect, useState } from 'react'
import './Logowork.css'
import Footer from "./Footer";
import Aos from "aos";
import 'aos/dist/aos.css';


function Logowork() {

    useEffect(() => {
        Aos.init({ duration:2000 })
    }, []);


  return (
    <div>
        <div className='container-social'>
                        <div className='down-content'>
                        <h3>Logo Design with Image</h3>
                        {/* <p>Creating trust in others for ourselves is a very difficult thing to do. The optimism that
                           comes with seeing it all is different.. Logo design will tell the audience "you can trust us".
                           One design carries a lot of responsibility. This logo designs are out to prove that your brands
                           are about big brains and even bigger imagination. Our logo design definetely communicate with your
                           targeted audience. 
                        </p> */}
                        </div>

<div class='image-container mt-5'>
    <div class='row'>
        <div class='col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center mb-4 middleimg-logo'>
            <img src="/Logo Design/photo_1_2024-04-15_14-07-44.jpg" class='img-fluid4' />
        </div>
        <div class='col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center mb-4 middleimg-logo'>
            <img src="/Logo Design/photo_2_2024-04-15_14-07-44.jpg" class='img-fluid4' />
        </div>
        <div class='col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center mb-4 middleimg-logo'>
            <img src="/Logo Design/photo_3_2024-04-15_14-07-44.jpg" class='img-fluid4' />
        </div>
        <div class='col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center mb-4 middleimg-logo'>
            <img src="/Logo Design/photo_4_2024-04-15_14-07-44.jpg" class='img-fluid4' />
        </div>

        <div class='col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center mb-4 middleimg-logo'>
            <img src="/Logo Design/photo_5_2024-04-15_14-07-44.jpg" class='img-fluid4' />
        </div>
        <div class='col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center mb-4 middleimg-logo'>
            <img src="/Logo Design/photo_6_2024-04-15_14-07-44.jpg" class='img-fluid4' />
        </div>
        <div class='col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center mb-4 middleimg-logo'>
            <img src="/Logo Design/photo_7_2024-04-15_14-07-44.jpg" class='img-fluid4' />
        </div>
        <div class='col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center mb-4 middleimg-logo'>
            <img src="/Logo Design/photo_8_2024-04-15_14-07-44.jpg" class='img-fluid4' />
        </div>

        <div class='col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center mb-4 middleimg-logo'>
            <img src="/Logo Design/photo_9_2024-04-15_14-07-44.jpg" class='img-fluid4' />
        </div>
        <div class='col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center mb-4 middleimg-logo'>
            <img src="/Logo Design/photo_10_2024-04-15_14-07-44.jpg" class='img-fluid4' />
        </div>
        <div class='col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center mb-4 middleimg-logo'>
            <img src="/Logo Design/photo_11_2024-04-15_14-07-44.jpg" class='img-fluid4' />
        </div>
        <div class='col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center mb-4 middleimg-logo'>
            <img src="/Logo Design/photo_12_2024-04-15_14-07-44.jpg" class='img-fluid4' />
        </div>   

        <div class='col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center mb-4 middleimg-logo'>
            <img src="/Logo Design/photo_13_2024-04-15_14-07-44.jpg" class='img-fluid4' />
        </div>
        <div class='col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center mb-4 middleimg-logo'>
            <img src="/Logo Design/photo_14_2024-04-15_14-07-44.jpg" class='img-fluid4' />
        </div>
        <div class='col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center mb-4 middleimg-logo'>
            <img src="/Logo Design/photo_15_2024-04-15_14-07-44.jpg" class='img-fluid4' />
        </div>
        <div class='col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center mb-4 middleimg-logo'>
            <img src="/Logo Design/photo_16_2024-04-15_14-07-44.jpg" class='img-fluid4' />
        </div> 

        <div class='col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center mb-4 middleimg-logo'>
            <img src="/Logo Design/photo_17_2024-04-15_14-07-44.jpg" class='img-fluid4' />
        </div>
        <div class='col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center mb-4 middleimg-logo'>
            <img src="/Logo Design/photo_18_2024-04-15_14-07-44.jpg" class='img-fluid4' />
        </div>
        <div class='col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center mb-4 middleimg-logo'>
            <img src="/Logo Design/photo_19_2024-04-15_14-07-44.jpg" class='img-fluid4' />
        </div>
        <div class='col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center mb-4 middleimg-logo'>
            <img src="/Logo Design/photo_20_2024-04-15_14-07-44.jpg" class='img-fluid4' />
        </div>       

        <div class='col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center mb-4 middleimg-logo'>
            <img src="/Logo Design/photo_21_2024-04-15_14-07-44.jpg" class='img-fluid4' />
        </div>
        <div class='col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center mb-4 middleimg-logo'>
            <img src="/Logo Design/photo_22_2024-04-15_14-07-44.jpg" class='img-fluid4' />
        </div>
        <div class='col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center mb-4 middleimg-logo'>
            <img src="/Logo Design/photo_23_2024-04-15_14-07-44.jpg" class='img-fluid4' />
        </div>
        <div class='col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center mb-4 middleimg-logo'>
            <img src="/Logo Design/photo_24_2024-04-15_14-07-44.jpg" class='img-fluid4' />
        </div>  

        <div class='col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center mb-4 middleimg-logo'>
            <img src="/Logo Design/photo_25_2024-04-15_14-07-44.jpg" class='img-fluid4' />
        </div>
        <div class='col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center mb-4 middleimg-logo'>
            <img src="/Logo Design/photo_26_2024-04-15_14-07-44.jpg" class='img-fluid4' />
        </div>
        <div class='col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center mb-4 middleimg-logo'>
            <img src="/Logo Design/photo_27_2024-04-15_14-07-44.jpg" class='img-fluid4' />
        </div>
        <div class='col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center mb-4 middleimg-logo'>
            {/* <img src="/Logo Design/photo_28_2024-04-15_14-07-44.jpg" class='img-fluid4' /> */}
        </div>           
    </div>
</div>

<p className='logo-content-p'>Creating trust in others for ourselves is a very difficult thing to do. The optimism that
                           comes with seeing it all is different. Logo design will tell the audience, "you can trust us".
                           One design carries a lot of responsibility. This logo designs are out to prove that your brands
                           are about big brains and even bigger imagination. Our logo design definitely communicates with your
                           targeted audience. 
                        </p>
                    </div>

                    <Footer />
    </div> 
  );
}; 


export default Logowork

