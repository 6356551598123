import React, { useEffect, useState } from 'react';
import Footer from "./Footer";
import Aos from "aos";
import 'aos/dist/aos.css';


function Videomaking() {
    

    useEffect(() => {
        Aos.init({ duration: 2000 });
    }, []);


    return (
        <div>
            <div className='container-social'>
                <div className='down-content'>
                <h3>Animated Video</h3>

                    <div className='image-container mt-5'>
                        <div className='row'>

                            <div className='col-lg-6 col-md-6 col-sm-12 mb-4'>
                                <div className='video-container'>
                                    <video height="240" controls loop muted autoPlay src="/Social_media/5_6118481831445660195.mp4" className='videos' controlsList="nodownload noremoteplayback"/>
                                </div>
                            </div>

                            <div className='col-lg-6 col-md-6 col-sm-12 mb-4'>
                                <div className='video-container'>
                                    <video height="240" controls loop muted autoPlay src="/Social_media/5_6118481831445660157.mp4" className='videos' controlsList="nodownload noremoteplayback"/>
                                </div>
                            </div>

                            <div className='col-lg-6 col-md-6 col-sm-12 mb-4'>
                                <div className='video-container'>
                                    <video height="240" controls loop muted autoPlay src="Videos/Sweets Promo.mp4" className='videos' controlsList="nodownload noremoteplayback"/>
                                </div>
                            </div>

                            <div className='col-lg-6 col-md-6 col-sm-12 mb-4 '>
                                <div className='video-container'>
                                    <video height="240" controls loop muted autoPlay src="Videos/Sweets Promo Final.mp4" className='videos' controlsList="nodownload noremoteplayback" />
                                </div>
                            </div>

                            <div className='col-lg-6 col-md-6 col-sm-12 mb-4 '>
                                <div className='video-container'>
                                    <video height="240" controls loop muted autoPlay src="/Social_media/5_6138644465222418889.mp4" className='videos' controlsList="nodownload noremoteplayback"/>
                                </div>
                            </div>

                            <div className='col-lg-6 col-md-6 col-sm-12 mb-4 '>
                                <div className='video-container'>
                                    <video height="240" controls loop muted autoPlay src="/Social_media/5_6131963424550158813.mp4" className='videos' controlsList="nodownload noremoteplayback"/>
                                </div>
                            </div>

                        </div>
                    </div>

                    <p>When you hear the word "animation". Your initial thought is likely Disney or Cartoons. This is
                        normal. So the thought want to be stable in people mind. That's what animation videos make. This
                        type of video most popular and has a superior ability to rotate the design 360 degree. Give products
                        a real life look. It is a perfect tool for attracting your new customers.
                    </p>

                </div>
            </div>
        <Footer />

        </div>
    );
}; 

export default Videomaking;
