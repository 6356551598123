import React, { useEffect, useState } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { fetchseventhsection, base_api } from './Axios/Axios';


function Quote() {

  const [seventhsection, setSeventhsection] = useState([]);
  const [isBackendError, setIsBackendError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const seventhsectiondata = await fetchseventhsection();
        setSeventhsection(seventhsectiondata)
      } catch (error) {
        setIsBackendError(true);
      } 
    };

    fetchData();
  }, [])

  useEffect(() => {
    Aos.init({ duration: 2000 })
  }, []);


  const renderSeventhsection = () => {
    const defaultData = {
      title: 'lets Create a measurable <br /> impact on your business',
      subtitle: 'Get Quotes',
    };

    if (isBackendError || !seventhsection.length) {
  return (
    <div className='quote-header'>
        <div className='quote-content'>
            <h2> Lets create a measurable<br/><span>impact on your business</span></h2>
            <button className="startbtn-quote" >
            <a href="/contact">Get Quote</a>
          </button>
        </div>
        <div className='quote-png'>
                <img src="Imagefile/7thsec.png"  className='quote-png1'/>
        </div>
    </div>
  );
} else {
  return seventhsection.map((item) => (
  <div className='quote-header'>
  <div className='quote-content'>
      <h2 dangerouslySetInnerHTML={{ __html: item.title || defaultData.title }} />
      <button className="startbtn-quote" href={item.button_link} >
      {item.button_text || defaultData.subtitle} 
    </button>
  </div>
  <div className='quote-png'>
          <img src={`${base_api}${item.png}`}  className='quote-png1'/>
  </div>
</div>
))
}
};


return (
  <div>
    {renderSeventhsection()}
  </div>
);
}

export default Quote;

