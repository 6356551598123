import React, { useEffect, useState } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import './Socialmediaposter.css';
import Footer from "./Footer";
import { fetchsocialmediaposterimages, base_api } from './Axios/Axios';


function Socialmediaposter() {

    useEffect(() => {
        Aos.init({ duration: 2000 });
    }, []);

  return (
    <div>
        <div className="container-social">
      <div data-aos="fade-left" className="pagein-content">
        <h3 >Social Media Creative with Image</h3>
        {/* <p>
        Having something special is a very important thing to keep someone
          following on the social network. The beauty that stands apart from
          everyone else will make everyone follow very quickly. Create an
          outstanding experience for your visitors. Our designers make your
          social media page standouts. Design should act as an enhancement to
          your content. Everyone definitely click the following button.. Hoo!
          Hoo!..
        </p> */}
      </div>
      <div className="image-container1 mt-5">
        {/* Images */}
        <div data-aos="fade-down" className="row">
              <div className="col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center mb-4 middleimg">
                <img src="/Social Media Poster/photo_1_2024-04-15_14-05-04.jpg" className="img-fluid2" />
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center mb-4 middleimg">
                <img src="/Social Media Poster/photo_2_2024-04-15_14-05-04.jpg" className="img-fluid2" />
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center mb-4 middleimg">
                <img src="/Social Media Poster/photo_3_2024-04-15_14-05-04.jpg" className="img-fluid2" />
              </div>

              <div className="col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center mb-4 middleimg">
                <img src="/Social Media Poster/photo_4_2024-04-15_14-05-04.jpg" className="img-fluid2" />
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center mb-4 middleimg">
                <img src="/Social Media Poster/photo_5_2024-04-15_14-05-04.jpg" className="img-fluid2" />
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center mb-4 middleimg">
                <img src="/Social Media Poster/photo_6_2024-04-15_14-05-04.jpg" className="img-fluid2" />
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center mb-4 middleimg">
                <img src="/Social Media Poster/photo_7_2024-04-15_14-05-04.jpg" className="img-fluid2" />
              </div>

              <div className="col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center mb-4 middleimg">
                <img src="/Social Media Poster/photo_8_2024-04-15_14-05-04.jpg" className="img-fluid2" />
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center mb-4 middleimg">
                <img src="/Social Media Poster/photo_2_2024-04-15_14-05-04.jpg" className="img-fluid2" />
              </div>
            </div>
      </div>

      <p className='social-content-p'>
        Having something special is a very important thing to keep someone
          following on the social network. The beauty that stands apart from
          everyone else will make everyone follow very quickly. Create an
          outstanding experience for your visitors. Our designers make your
          social media page standouts. Design should act as an enhancement to
          your content. Everyone definitely click the following button. Hoo!
          Hoo!..
        </p>
    </div>

    <Footer />

    </div>
  );
};


export default Socialmediaposter


