// import React, { useEffect, useState } from 'react'
// import './Ecommerce.css'
// import 'aos/dist/aos.css';
// import { fetchecommerceservice, base_api } from './Axios/Axios';

// function Ecommerce() {

//   const [services, setServices] = useState([]);

//   const [firstsection, setFirstsection] = useState([]);
//   const [isBackendError, setIsBackendError] = useState(false);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const firstsectiondata = await fetchecommerceservice();
//         setFirstsection(firstsectiondata);
//       } catch (error) {
//         console.error('Error fetching data:', error);
//         setIsBackendError(true);
//       }
//     };

//     fetchData();
//   }, []);


//   const rendercommersection = () => {
//     if (isBackendError || !firstsection.length) {
//   return (
//     <div>
//     <div className="serviceaboutsection">
//       <div className="aboutcontent">
//         <div  className="aboutsection-image-all-service-commerce">
//           <img
//             src="/Imagefile/Ecommerce.jpg"
//             className="aboutsection-image"
//           />
//         </div>

//         <div className="commerce-text-center">
//           <h1>E-commerce Website Developement</h1>
//           <div className="commerce-underline"></div>
//           <p>
//             We focus on Tailoring B2B & B2C E-commerce web applications that
//             compel the users to buy your products/services.
//           </p>
//         </div>
//       </div>
//     </div>
//   </div>
// );
// } else {
//   return firstsection.map((item) => (
//   <div key={item.id}>
//     <div className="serviceaboutsection">
//       <div className="aboutcontent">
//         <div  className="aboutsection-image-all-service-commerce">
//           <img
//             src={`${base_api}${item.image}`}
//             className="aboutsection-image"
//           />
//         </div>

//         <div className="commerce-text-center">
//           <h1 dangerouslySetInnerHTML={{ __html: item.text }} />
//           <div className="commerce-underline"></div>
//           <p dangerouslySetInnerHTML={{ __html: item.content }} />
//         </div>
//       </div>
//     </div>
//   </div>
//   ))
// }
// };

// return(
//   <div>
//     {rendercommersection()}
//   </div>
// );
// }

// export default Ecommerce




///// change to ecommerce page


import React from 'react'
import './Ecommerce.css'
import 'aos/dist/aos.css';

function Ecommerce() {

  return (
    <div>
    <div className="serviceaboutsection">
      <div className="aboutcontent">
        <div  className="aboutsection-image-all-service-commerce">
          <img
            src="/Imagefile/E commerce Website devlopment.png"
            className="aboutsection-image"
          />
        </div>

        <div className="commerce-text-center">
          <h1>E-commerce Website Development</h1>
          <div className="commerce-underline"></div>
          <p>
            We focus on Tailoring B2B & B2C E-commerce web applications that
            compel the users to buy your products/services.
          </p>
        </div>
      </div>
    </div>
  </div>
);
}

export default Ecommerce
